import * as React from "react"
import { graphql } from "gatsby"
import { StaticImage, getSrc} from "gatsby-plugin-image"



const IndexPage = ({ data }: any) => {
  const { heroImage } = data

  const heroStyle = {
    backgroundImage: `url(${getSrc(heroImage)})`,
    backgroundPosition: 'center'
  }

  return (
    <main className="w-full bg-orange-50">
      <div style={heroStyle} className="h-96 w-full">
        <div className="mx-auto max-w-4xl h-full py-40 text-center">
          <h1 className="text-6xl font-bold">
            MZ32.DEV
          </h1>
          <span>Web Engineer</span>
        </div>
      </div>
      <header className="w-full bg-blue-400 border-orange-200 border-b-2 sticky top-0 z-50">
        <ul className="mx-auto max-w-4xl text-white text-center">
          <li className="inline-block w-1/4 py-2">PROFILE</li>
          <li className="inline-block w-1/4 py-2">SKILL</li>
          <li className="inline-block w-1/4 py-2">WORK</li>
          <li className="inline-block w-1/4 py-2">CONNTACT</li>
        </ul>
      </header>
      <section className="mx-auto max-w-4xl md:grid grid-cols-3 gap-2 py-6">
        <h2 className="col-span-3 text-center pt-10 pb-6 font-bold text-3xl">PROFILE</h2>
        <div className="w-full">
          <StaticImage
            src="../images/common/avatar.png"
            alt="A dinosaur"
            placeholder="blurred"
            layout="fullWidth"
            width={200}
            height={200}
            className="mx-auto w-36 md:max-w-none"
          />
          </div>
        <div className="col-span-2">hogehoge</div>
      </section>


      <section className="mx-auto max-w-4xl md:grid grid-cols-2 gap-6 py-6">
        <h2 className="col-span-2 text-center pt-10 pb-6 font-bold text-3xl">SKILL</h2>


        <div className="bg-white shadow-md my-4 md:m-0 mx-auto max-w-sm md:max-w-none">
          <h3 className="text-center py-2 text-lg font-semibold">Frontend</h3>
          <div>

          </div>
        </div>

        <div className="bg-white shadow-md my-4 md:m-0 mx-auto max-w-sm md:max-w-none">
          <h3 className="text-center py-2 text-lg font-semibold ">Backend</h3>
          <div>

          </div>
        </div>
      </section>

      <section className="mx-auto max-w-4xl md:grid grid-cols-2 gap-6 py-6">
        <h2 className="col-span-2 text-center pt-10 pb-6 font-bold text-3xl">WORK</h2>

        <div className="bg-white shadow-md my-4 md:m-0 mx-auto max-w-sm md:max-w-none">
          <StaticImage
            src="../images/common/noimages.png"
            alt="A dinosaur"
            placeholder="blurred"
            layout='fullWidth'
            width={426}
            height={240}
          />
          <div className="block w-full">タイトル</div>
        </div>

        <div className="bg-white shadow-md my-4 md:m-0 mx-auto max-w-sm md:max-w-none">
          <StaticImage
            src="../images/common/noimages.png"
            alt="A dinosaur"
            placeholder="blurred"
            layout='fullWidth'
            width={426}
            height={240}
          />
          <div className="block w-full">タイトル</div>
        </div>


        <div className="col-span-2 text-right">and more. </div>
      </section>

      <section className="mx-auto max-w-4xl grid grid-cols-3 gap-2 py-6">
        <h2 className="col-span-3 text-center pt-10 pb-6 font-bold text-3xl">CONTACT</h2>
        <div>
          <StaticImage
            src="../images/common/avatar.png"
            alt="A dinosaur"
            placeholder="blurred"
            layout="fixed"
            width={200}
            height={200}
            className="w-full"
          />
        </div>
        <div className="col-span-2">hogehoge</div>
      </section>
    </main>
  )
}

export default IndexPage


export const pageQuery = graphql`
  query {
    heroImage: file(relativePath: { eq: "top/hero.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
